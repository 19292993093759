export default [
  {
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    title: "Brunch this weekend?",
    subtitle: "<span class='text--primary'>Ali Connors</span> &mdash; I'll be in your neighborhood ?"
  },
  { divider: true, inset: true },
  {
    avatar: "https://randomuser.me/api/portraits/men/2.jpg",
    title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
    subtitle:
      "<span class='text--primary'>to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend."
  },
  { divider: true, inset: true },
  {
    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
    title: "Oui oui",
    subtitle:
      "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?"
  },
  { divider: true, inset: true },
  {
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    title: "Dash",
    subtitle:
      "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?"
  }
]
